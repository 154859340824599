<template>
	<transition name="page" mode="out-in">
		<header
			v-if="headingImage"
			ref="headingImageRef"
			:key="headingImage.image"
			:class="{ subpage: !route.name.startsWith('index') }"
		>
			<div v-if="route.name.startsWith('index')">
				<div v-if="headingImage.header" :class="headingImage.color" class="slider-content">
					<div class="row">
						<div class="columns column12">
							<h1>{{ headingImage.header }}</h1>
						</div>
					</div>
				</div>
				<a class="scroll-down smooth-scroll" @click="scrollDown()" />
				<div style="padding: 56.25% 0 0; position: relative">
					<iframe
						:src="
							'https://player.vimeo.com/video/' +
							vimeoid +
							'?h=48e73401ce&loop=1&muted=1&badge=0&autoplay=1&controls=0&autopause=0&player_id=0&app_id=58479'
						"
						frameborder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowfullscreen
						style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 2"
						title="Aedes - Durgerdam"
					/>
				</div>
				<div class="video-bg" />
			</div>
			<div v-else class="slider">
				<div class="slides">
					<div v-if="headingImage.header" :class="headingImage.color" class="slider-content">
						<div class="row">
							<div class="columns column12">
								<h1>{{ headingImage.header }}</h1>
							</div>
						</div>
					</div>
					<div v-if="vimeoid">
						<div style="padding: 56.25% 0 0; position: relative">
							<iframe
								:src="
									'https://player.vimeo.com/video/' +
									vimeoid +
									'?h=48e73401ce&loop=1&muted=1&badge=0&autoplay=1&controls=0&autopause=0&player_id=0&app_id=58479'
								"
								class="subpage-iframe"
								frameborder="0"
								allow="autoplay; fullscreen; picture-in-picture"
								allowfullscreen
								style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
								title="Aedes - Durgerdam"
							/>
						</div>
					</div>
					<div v-else>
						<picture>
							<source
								v-if="headingImage.imagePortrait && headingImage.imagePortraitWebp"
								:srcset="headingImage.imagePortraitWebp"
								media="(max-width: 500px)"
								type="image/webp"
							/>
							<source
								v-if="headingImage.imagePortrait"
								:srcset="headingImage.imagePortrait"
								media="(max-width: 500px)"
							/>
							<source v-if="headingImage.imageWebp" :srcset="headingImage.imageWebp" type="image/webp" />
							<source :srcset="headingImage.image" />
							<img class="slider-image" :src="headingImage.image" :alt="headingImage.imageAlt" />
						</picture>
					</div>
				</div>
			</div>
		</header>
	</transition>
</template>

<script setup>
const route = useRoute();

defineProps({
	headingImage: { type: Object, default: () => {} },
	defaults: { type: Object, default: () => {} },
	vimeoid: { type: String, default: '' },
	slug: { type: String, default: '' },
});

const headingImageRef = ref(null);

const scrollDown = () => {
	const scrollHeight = headingImageRef.value.clientHeight - 82;
	window.scroll({
		top: scrollHeight,
		left: 0,
		behavior: 'smooth',
	});
};
</script>

<style lang="scss" scoped>
header {
	position: relative;
	overflow: hidden;
	z-index: 1;
	max-height: 100vh;

	.row {
		max-width: 1220px;
	}

	&.subpage {
		margin-top: 170px;
		padding: 0 30px;
	}
}

.slider,
.slider .slides {
	height: 100vh;
	position: relative;
}

.slider-image {
	height: 100%;
	width: 100%;
	object-fit: cover;
	animation: fade-in 800ms ease-out;
}

.slider-content {
	position: absolute;
	top: 43%;
	left: 0;
	right: 0;
	color: #fff;
	z-index: 3;
	animation: fade-from-bottom 2s ease;

	h2,
	h1 {
		font-size: 58px;
		line-height: 72px;
		color: #fff;
		text-align: center;
	}

	&.color-orange {
		h1,
		h2,
		h3 {
			color: #d64e30;
		}
	}

	&.color-blue {
		h1,
		h2,
		h3 {
			color: #6689c5;
		}
	}

	&.color-green {
		h1,
		h2,
		h3 {
			color: #0f2d16;
		}
	}
}

.subpage {
	header {
		max-width: 1900px;
		margin: 170px auto 0;
	}

	.slider,
	.slider .slides {
		height: auto;
	}

	.slider .slides {
		padding-top: 87px;
	}

	.slider-content {
		top: 0;
		transform: none;

		h2,
		h1 {
			font-size: 118px;
			line-height: 148px;
			color: $footer-background-color;
		}

		&.color-orange {
			h1,
			h2,
			h3 {
				color: #d64e30;
			}
		}

		&.color-blue {
			h1,
			h2,
			h3 {
				color: #6689c5;
			}
		}

		&.color-green {
			h1,
			h2,
			h3 {
				color: #0f2d16;
			}
		}
	}
}

.scroll-down {
	position: absolute;
	cursor: pointer;
	bottom: 30px;
	left: 0;
	right: 0;
	margin: 0 auto;
	background: url('~/assets/images/scroll-down-arrow.svg') no-repeat center center;
	width: 52px;
	height: 52px;
	background-size: 52px;
	z-index: 3;
}

.video-bg {
	background: url('~/assets/images/video-bg-blur.jpg') no-repeat center center;
	position: absolute;
	inset: 0;
	z-index: 1;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media (max-width: 1180px) {
	.slider-content {
		h1,
		h2 {
			font-size: 50px;
			line-height: 60px;
		}
	}
}

@media (max-width: 960px) {
	.slider-content {
		h1,
		h2 {
			font-size: 42px;
			line-height: 54px;
		}
	}

	.subpage {
		.slider-content {
			h1,
			h2 {
				font-size: 42px;
				line-height: 54px;
			}
		}

		header,
		.slider,
		.slides {
			min-height: 100px;
		}

		header {
			img {
				min-height: 300px;
			}
		}
	}

	header {
		min-height: 640px;

		iframe {
			width: 360% !important;
			height: 130% !important;
			margin-left: -130% !important;
			min-height: 640px !important;

			&.subpage-iframe {
				width: 100% !important;
				height: 100% !important;
				margin-left: 0 !important;
				min-height: 100px !important;
			}
		}
	}
}

@media (max-width: 720px) {
	.slider .slides {
		transform: none !important;
	}

	.slider-content {
		bottom: 40%;

		h1,
		h2 {
			font-size: 28px;
			line-height: 34px;
		}
	}

	.subpage {
		.slider {
			.slides {
				padding-top: 24px;
			}
		}

		.slider-content {
			h1,
			h2 {
				font-size: 38px;
				line-height: 42px;
			}
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
	.slider,
	.slider .slides {
		height: 480px;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
	.slider,
	.slider .slides {
		height: 568px;
	}
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
	.slider,
	.slider .slides {
		height: 667px;
	}
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
	.slider,
	.slider .slides {
		height: 736px;
	}
}
</style>
